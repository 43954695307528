.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 17.5vw;
  height: 100vh;
  background-color: #fcfcfc;
  box-shadow: 1rem 0 10rem #eeeeee;

  &-user {
    height: calc(9vh - 1px);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 1.2rem 1rem;

    h2 {
      color: $color-main;
      font-size: 1.8rem;
      font-weight: 600;
    }

    border-bottom: 1px solid #dddddd;

    &-login {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      text-decoration: none;
      border-radius: 0.5rem;
      padding: 1.3rem;
      transition: $transition;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  &__nav {
    height: calc(100vh - 10.2rem);
    display: flex;
    flex-direction: column;
    padding: 1.2rem 1rem 0rem;

    &-link {
      display: flex;
      align-items: center;
      color: #777777;
      text-decoration: none;
      border-radius: 0.5rem;
      transition: $transition;
      font-weight: 400;
      font-size: 1.6rem;
      padding: 1rem;
      margin-bottom: 0.5rem;

      &:hover {
        background-color: #f5f5f5;
      }
    }

    &-logout {
      margin-top: auto;
      margin-bottom: 0;

      &:hover {
        cursor: pointer;
      }
    }
  }

  &-icon {
    color: $color-main;
    font-size: 2rem;

    &_container {
      height: 2.8rem;
      width: 2.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.5rem;
    }

    &_user {
      margin-right: 1.5rem;
    }

    &_products {
      color: $color-main;
    }

    &_logout {
      color: #ffa500;
    }
  }
}
