.product {
  // height: calc(100vh - 20rem);
  // width: calc(82.5vw - 20rem);
  height: auto;
  width: calc(100vw - 17.5vw);
  padding: 5rem 10rem;
  margin-left: auto;

  &-container {
    width: 65%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 1rem;
    // box-shadow: 0 0 10rem #eeeeee;
    margin: auto;
    margin-bottom: 4rem;
  }

  &bar {
    width: 85%;
    display: flex;
    align-items: center;
    border-radius: 1rem;
    background-color: #fcfcfc;
    padding: 1.4rem 1.8rem;
    margin-right: 1rem;

    p {
      color: $color-main;
      font-size: 2rem;
    }

    &-icon {
      &:hover {
        &-container {
          background-color: #fcfcfc;
        }
      }

      &-container {
        display: flex;

        transition: $transition;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        height: 6rem;
        width: 8rem;
        border-radius: 1rem;
        background-color: $color-main;
        font-size: 2rem;
        color: #fcfcfc;

        &:hover {
          color: $color-main;
          background-color: #fcfcfc;
        }
      }
    }

    &-input {
      font-size: 1.6rem !important;
    }
  }

  &-details {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    padding: 1.6rem;
    background-color: #fcfcfc;
    font-size: 1.6rem;

    &--name {
      font-size: 2rem;
      margin-bottom: 1.4rem;
    }

    &--seller {
    }

    &--price {
    }
  }
}
