@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-10rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(10rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(-10rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
