@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
$font-family_main: "Lato", sans-serif;

// @import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");
// $font-family_login: "Roboto Condensed", sans-serif;

// colors
$color-main: #228be6;
$color-main--dark: #114673;
// $color-main__variant: #b43b2b;
// $color-contact: #74b816;

// transition
$transition: all 0.2s;

// font-size
// $fs-text: 1.6rem;
// $fs-section-heading: 2.8rem;
// $fs-section-heading-mobile: 2.4rem;
// $fs-heading: 2rem;

// gradients
// $gradient-main: linear-gradient(258deg, #b42b51 -1.61%, #b43b2b 97.23%);

// spacing
// $spacing-1: 0.8rem;
// $spacing-2: 1.6rem;
// $spacing-3: 2.4rem;
// $spacing-4: 4rem;
// $spacing-5: 6.4rem;

// borders
// $br-small: 5px;

// $section-max-width: 120rem;
// $section-padding-pc: 10%;
// $section-padding-tab: 5%;
