*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-family_main;
  &::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: $font-family_main;
  }
  &::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-family: $font-family_main;
  }
}

html {
  /* 1rem = 10px */
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  background-color: #eeeeee;
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.main-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  overflow: auto;
}

.loading-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  font-size: 2rem;
  color: $color-main;
}

.lateral-padding {
  padding: 0 2rem;
}

/////TOSTIFY/////
.Toastify__toast--error {
  font-size: 1.6rem !important;
}

.Toastify__toast--success {
  font-size: 1.6rem !important;
}
