.result-a {
  text-decoration: none;
  transition: $transition;

  &:hover {
    transform: translateX(1.4rem);
  }

  &:visited {
    .result {
      &-url {
        color: rgb(130, 147, 0);
      }
    }
  }

  .result {
    width: 100%;
    height: auto;
    position: relative;
    font-size: 1.4rem;
    background-color: #fcfcfc;

    display: flex;
    align-items: center;

    gap: 1.6rem;

    box-shadow: 0 0 10rem #eeeeee;

    padding: 1.6rem;
    border-radius: 1rem;

    &-price {
      text-align: left;
      font-weight: 700;
      font-size: 1.8rem;
      color: $color-main;
    }

    &-name {
      font-size: 1.6rem;
      color: #000;
    }

    &-url {
      position: absolute;
      right: 2rem;
      font-size: 1.6rem;
      align-self: flex-end;
      color: $color-main;
    }

    &-save--icon {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;

      font-size: 2rem;
      color: $color-main;
      cursor: pointer;
      transition: $transition;
    }
  }
}
